var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "px-8",
    attrs: {
      "elevation": "0"
    }
  }, [_vm.register ? _c('v-card-title', {
    staticClass: "text-h5 text-lg-h4 text-xl-h3 pb-8 font-weight-bold"
  }, [_vm._v(" Set Password ")]) : _c('v-card-title', {
    staticClass: "text-h5 text-lg-h4 text-xl-h3 pb-8 font-weight-bold"
  }, [_vm._v(" Change Password ")]), _c('v-card-text', [_vm.email ? _c('span', [_vm._v("for " + _vm._s(_vm.email))]) : _vm._e(), _c('v-form', {
    ref: "confirmPassForm",
    attrs: {
      "lazy-validation": ""
    },
    model: {
      value: _vm.confirmPassForm,
      callback: function ($$v) {
        _vm.confirmPassForm = $$v;
      },
      expression: "confirmPassForm"
    }
  }, [_c('v-text-field', {
    attrs: {
      "background-color": "white",
      "placeholder": "Password",
      "rules": [function (v) {
        return !!v || 'Password needed';
      }],
      "type": _vm.visiblePass ? 'text' : 'password'
    },
    model: {
      value: _vm.password,
      callback: function ($$v) {
        _vm.password = $$v;
      },
      expression: "password"
    }
  }, [!_vm.visiblePass ? _c('v-icon', {
    attrs: {
      "slot": "append",
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        _vm.visiblePass = !_vm.visiblePass;
      }
    },
    slot: "append"
  }, [_vm._v(" " + _vm._s(_vm.mdiEye) + " ")]) : _c('v-icon', {
    attrs: {
      "slot": "append",
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        _vm.visiblePass = !_vm.visiblePass;
      }
    },
    slot: "append"
  }, [_vm._v(" " + _vm._s(_vm.mdiEyeOff) + " ")])], 1), _c('v-text-field', {
    attrs: {
      "background-color": "white",
      "placeholder": "Confirm Password",
      "rules": [function (v) {
        return !!v || 'Confirm password again';
      }, function (v) {
        return v === _vm.password || 'Passwords do not match';
      }],
      "type": _vm.visibleConfirmPass ? 'text' : 'password'
    },
    model: {
      value: _vm.confirmationPassword,
      callback: function ($$v) {
        _vm.confirmationPassword = $$v;
      },
      expression: "confirmationPassword"
    }
  }, [!_vm.visibleConfirmPass ? _c('v-icon', {
    attrs: {
      "slot": "append",
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        _vm.visibleConfirmPass = !_vm.visibleConfirmPass;
      }
    },
    slot: "append"
  }, [_vm._v(" " + _vm._s(_vm.mdiEye) + " ")]) : _c('v-icon', {
    attrs: {
      "slot": "append",
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        _vm.visibleConfirmPass = !_vm.visibleConfirmPass;
      }
    },
    slot: "append"
  }, [_vm._v(" " + _vm._s(_vm.mdiEyeOff) + " ")])], 1)], 1), _c('v-card-actions', {
    staticClass: "flex-column"
  }, [_c('v-btn', {
    staticClass: "rounded-md text-capitalize pa-5 white--text font-weight-medium text-h6",
    attrs: {
      "width": "100%",
      "color": "#2EAC68",
      "disabled": !_vm.confirmPassForm,
      "loading": _vm.submitted,
      "elevation": "0"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.validateconfirmPassForm.apply(null, arguments);
      }
    }
  }, [_vm._v(" Confirm ")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }