<template>
  <v-card elevation="0" class="px-8">
    <v-card-title
      v-if="register"
      class="text-h5 text-lg-h4 text-xl-h3 pb-8 font-weight-bold"
    >
      Set Password
    </v-card-title>
    <v-card-title
      v-else
      class="text-h5 text-lg-h4 text-xl-h3 pb-8 font-weight-bold"
    >
      Change Password
    </v-card-title>
    <v-card-text>
      <span v-if="email">for {{ email }}</span>
      <v-form v-model="confirmPassForm" ref="confirmPassForm" lazy-validation>
        <v-text-field
          background-color="white"
          placeholder="Password"
          v-model="password"
          :rules="[(v) => !!v || 'Password needed']"
          :type="visiblePass ? 'text' : 'password'"
        >
          <v-icon
            @click.prevent="visiblePass = !visiblePass"
            slot="append"
            v-if="!visiblePass"
            color="primary"
          >
            {{ mdiEye }}
          </v-icon>

          <v-icon
            @click.prevent="visiblePass = !visiblePass"
            slot="append"
            v-else
            color="primary"
          >
            {{ mdiEyeOff }}
          </v-icon>
        </v-text-field>
        <v-text-field
          background-color="white"
          placeholder="Confirm Password"
          v-model="confirmationPassword"
          :rules="[
            (v) => !!v || 'Confirm password again',
            (v) => v === password || 'Passwords do not match',
          ]"
          :type="visibleConfirmPass ? 'text' : 'password'"
        >
          <v-icon
            @click.prevent="visibleConfirmPass = !visibleConfirmPass"
            slot="append"
            v-if="!visibleConfirmPass"
            color="primary"
          >
            {{ mdiEye }}
          </v-icon>

          <v-icon
            @click.prevent="visibleConfirmPass = !visibleConfirmPass"
            slot="append"
            v-else
            color="primary"
          >
            {{ mdiEyeOff }}
          </v-icon>
        </v-text-field>
      </v-form>
      <v-card-actions class="flex-column">
        <v-btn
          @click.prevent="validateconfirmPassForm"
          class="rounded-md text-capitalize pa-5 white--text font-weight-medium text-h6"
          width="100%"
          color="#2EAC68"
          :disabled="!confirmPassForm"
          :loading="submitted"
          elevation="0"
        >
          Confirm
        </v-btn>
      </v-card-actions>
    </v-card-text>
  </v-card>
</template>
<script>
import { mdiEye, mdiEyeOff } from '@mdi/js'
import firebase from 'firebase'
import { mapActions } from 'vuex'

export default {
  methods: {
    ...mapActions('customer', ['getResetCode']),
    validateconfirmPassForm() {
      !this.error &&
        this.$refs.confirmPassForm.validate() &&
        this.doPasswordChange()
    },
    doPasswordChange() {
      this.submitted = true
      const resetCode = this.resetCode
      firebase
        .auth()
        .confirmPasswordReset(resetCode, this.password)
        .then((res) => {
          this.postLogin()
        })
        .catch((error) => {
          this.$store.dispatch('notifs/addNotif', {
            text: error?.message ?? 'Some error occurred, please try again!',
            type: 'error',
          })
          this.submitted = false
        })
    },
    postLogin() {
      this.$store.dispatch('notifs/addNotif', {
        text: `Successfully Updated Password!`,
        type: 'success',
      })
      if (this.$route.query.continueUrl) {
        const forwardedRoute = this.$route.query.continueUrl
          .split('https://codedrills.io')
          .filter((c) => c.length > 0)[0]
        // example: https://codedrills.io/login -> ["", "/login"] -> "/login"
        this.$router.push(forwardedRoute)
      } else {
        this.$router.push('/login')
      }
    },
    invalidLinkError() {
      this.$store
        .dispatch('notifs/addNotif', {
          text: 'Invalid password reset link',
          type: 'error',
          duration: 10000,
        })
        .then(() => {
          // clean query
          this.$router.replace({ query: null })
          this.error = true
        })
    },
  },
  created() {
    this.resetCode = this.$route.query?.oobCode
    if (!this.resetCode || this.$route.query?.mode !== 'resetPassword') {
      console.log("No reset code or mode isn't resetPassword")
      this.invalidLinkError()
      return
    }
    this.register = this.$route.query?.register
    firebase
      .auth()
      .verifyPasswordResetCode(this.resetCode)
      .then((email) => {
        this.email = email
      })
      .catch(() => {
        var inviteCode = this.$route.query?.inviteCode
        console.log('Invite Code: ', inviteCode)
        if (inviteCode) {
          this.getResetCode({ inviteCode: inviteCode })
            .then((res) => {
              this.resetCode = res.getResetCode()
            })
            .catch((ex) => {
              console.log('Error when getting reset code', ex)
              this.invalidLinkError()
            })
        } else {
          console.log('No invite code')
          this.invalidLinkError()
        }
      })
  },
  data() {
    return {
      mdiEye,
      mdiEyeOff,
      confirmPassForm: false,
      confirmationPassword: '',
      password: '',
      visiblePass: false,
      visibleConfirmPass: false,
      submitted: false,
      email: undefined,
      register: false,
      error: false,
      resetCode: undefined,
    }
  },
}
</script>
<style scoped>
.divider {
  display: flex;
  flex-direction: row;
  color: #909090;
}
.divider:before,
.divider:after {
  content: '-';
  margin: auto;
}
.divider:before {
  margin-right: 10px;
}
.divider:after {
  margin-left: 10px;
}
a {
  color: #2eac68;
  text-decoration-line: none;
}
</style>
